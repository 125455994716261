
.css-169iwlq-MuiCalendarPicker-root{
    background-color: #030303;
}

.css-7uzxso-MuiButtonBase-root-MuiPickersDay-root{
    background-color: #151517;
}

.css-1jm66jt-MuiClockPicker-root{
    background-color: #030303;
}

.css-1brzq0m{
    background-color: #030303;
}

.css-v620np{
    background-color: #151517;
}
.tox .tox-toolbar-overlord .tox-toolbar:not(.tox-toolbar--scrolling):first-child, .tox .tox-toolbar-overlord .tox-toolbar__primary{
    background-color: #151517;
}
.tox .tox-menubar{
    background-color: #151517 !important;
}
.tox .tox-mbtn__select-label{
    color: white;
}
.tox .tox-tbtn{
    background: whitesmoke !important;
}
.mce-content-body{
    background-color: #151517 !important;
}
.tox .tox-statusbar{
    background-color: #151517 !important;
}
.tox .tox-edit-area__iframe{
    background-color: #151517 !important;
}
/* .tox:not([dir=rtl]){
    background-color: #151517 !important;
} */
.tox:not(.tox-tinymce-inline) .tox-editor-header{
    background-color: #151517 !important;
}
.mce-content-body {
    color: white !important;
}
.tox .tox-menu.tox-collection.tox-collection--list{
    background-color: #151517 !important;
}
.tox:not([dir=rtl]) .tox-collection--list .tox-collection__item>.tox-collection__item-label:first-child{
    color: white;
}
.tox:not([dir=rtl]) .tox-collection--list .tox-collection__item>:not(:first-child){
    color: white;
}
.tox .tox-collection__item-checkmark svg, .tox .tox-collection__item-icon svg{
    color: white;
}